import React, { PropsWithChildren } from 'react';
import { useFormikError } from '../useFormikError';
import { Tags, TagsProps } from './Tags';
export const TagsField = <T,>(
  props: PropsWithChildren<Omit<TagsProps<T>, 'error'>>,
): JSX.Element => {
  const error = useFormikError(props.name);

  return <Tags {...props} error={error} />;
};
