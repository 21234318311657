import { Data } from '../../types/data';

export enum ListSelectMode {
  None,
  Single,
  Multi,
}

export type ColumnRenderer<T extends Data> =
  /**
   * @param value Column data
   * @param data Complete row data
   */
  (value: unknown, data: T) => React.ReactNode;

export interface ColumnSortKeys {
  ascending: string;
  descending: string;
}

interface ListUnboundColumn<T extends Data> extends ListBaseColumn<T> {
  /** The name of the property on the Data object that should be rendered in that column. */
  propertyName?: keyof T;
  /**
   * A custom render function that will be called for every row that should be rendered.
   * The List will already try to render the data in a meaningful way, but in some advanced use cases more control is required.
   * @memberof Column
   */
  render: ColumnRenderer<T>;

  /**
   * The identifier for the column.
   */
  key: string;
}

interface ListBoundColumn<T extends Data> extends ListBaseColumn<T> {
  /** The name of the property on the Data object that should be rendered in that column. */
  propertyName: keyof T;

  /**
   * A custom render function that will be called for every row that should be rendered.
   * The List will already try to render the data in a meaningful way, but in some advanced use cases more control is required.
   * @memberof Column
   */
  render?: ColumnRenderer<T>;

  /**
   * The identifier for the column. (Default: propertyName)
   */
  key?: string;
}

interface ListBaseColumn<T extends Data> {
  /** The width of the column as CSS size (default: 1fr) */
  size?: string;
  /** Column's header label */
  label?: string;
  /** If set to false, disables sorting for this column (default: true) */
  sortable?: boolean;
  /**
   * A custom render function that will be called for every row that should be rendered.
   * The List will already try to render the data in a meaningful way, but in some advanced use cases more control is required.
   * @memberof Column
   */
  render?: ColumnRenderer<T>;
  /**
   * If set to false, will not render a tooltip for the column data (default: undefined)
   * If a custom renderer is used that will not return a value cast-able to a string, you should consider setting this property to false and add a title attribute on the component returned by the custom renderer.
   */
  tooltip?: boolean;
  /** Additional data passed into the render function */
  extras?: unknown;

  /** Specify explicit enum keys to use for ascending and descending sorting */
  columnSortKeys?: ColumnSortKeys;

  /** Specify the horizontal text alignment of the column */
  horizontalColumnAlign?: 'left' | 'center' | 'right';

  /** If set to true, the column will not be resizable */
  disableResizing?: boolean;
}

export type Column<T extends Data> = ListUnboundColumn<T> | ListBoundColumn<T>;

export interface ColumnMap {
  [key: string]: string;
}

export interface ListItem<T extends Data> {
  selected: boolean;
  data: T;
}

export interface ItemSelectEventArgs<T extends Data> {
  mode: 'SINGLE_ITEMS' | 'SELECT_ALL';
  items?: T[];
}

export interface SortData<T> {
  /** Name of the property to sort by */
  column: keyof T;
  /** Sorting direction */
  direction: 'asc' | 'desc';

  columnSortKey?: string;
}

export interface ListElement {
  /**
   * Resets the selection of the list.
   */
  resetSelection: () => void;

  /**
   * Selects an index in the list.
   */
  selectIndex: (index: number) => void;
}
