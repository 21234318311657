import clsx from 'clsx';
import React from 'react';
import classes from './FormGrid.scss';

export interface FormGridProps {
  edgeToEdgeContent?: boolean;
}

export const FormGrid: React.FC<FormGridProps> = ({
  children,
  edgeToEdgeContent,
}) => {
  return (
    <div
      className={clsx(
        {
          [classes.container]: !edgeToEdgeContent,
        },
        classes.content,
      )}
    >
      {children}
    </div>
  );
};
